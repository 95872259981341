.bottom_section {
    position: absolute;
    width: 93%;
    padding: 3rem;
    background-color: #00567a;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; /* Vertically align content */
}

.bottom_title {
    /* margin-right: 30rem; */
    /* margin-left: -1rem; */
    margin-top: 2rem;
    /* margin-left: 2rem; */
    margin-bottom: 6rem;
    font-size: 4.5rem;
    font-family: 'Noto Serif';
    color: white;
    flex-wrap: nowrap;
    display: flex;
    /* width: 80rem; */
}

.bottom_content {
    width: 50%;
    margin: 2rem;
    margin-left: 0rem;
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertically align content */
}

.bottom_statement {
    width: 100%;
    font-size: 1rem;
    color: white;
}

.contact_info {
    font-size: 1rem;
    color: white;
    display: flex;
    align-items: center; /* Vertically align content */
    margin-left: 20px; /* Add some left margin for spacing */
}

.bottom_section_top_message {
    font-size: 1.25rem;
    font-family: 'Noto Serif';
}

.bottom_section_pages_container {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    width: 50rem;
}

.bottom_section_medical {
    font-size: 1.25rem;
    display: flex;
    margin-right: 3rem;
    /* width: 10rem; */
    font-family: 'Noto Serif';
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    padding-inline: 0.1rem;
    padding-bottom: 0.3rem;
}

.bottom_section_education {
    font-size: 1.25rem;
    display: flex;
    margin-right: 3rem;
    font-family: 'Noto Serif';
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    padding-inline: 0.1rem;
    padding-bottom: 0.3rem;
    /* margin-inline-end: 2rem; */
    /* width: 10rem; */
}

.bottom_section_founder {
    font-size: 1.25rem;
    display: flex;
    margin-right: 3rem;
    font-family: 'Noto Serif';
    /* width: 10rem; */
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    padding-inline: 0.1rem;
    padding-bottom: 0.3rem;
}

.bottom_section_past_work {
    font-size: 1.25rem;
    display: flex;
    flex-wrap: nowrap;
    /* width: 20rem; */
    margin-right: 3rem;
    font-family: 'Noto Serif';
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    padding-inline: 0.1rem;
    padding-bottom: 0.3rem;
}

.bottom_section_bottom {
    width: 40rem;
    border-top-style: solid;
    border-top-width: 0.1rem;
    margin-top: 2rem;
}


.contact_us_header {
    font-weight: bold;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.contact_us_section {
    margin-top: 2rem;
    font-family: 'Noto Serif';
}

.phone {
    font-size: 1.25rem;
    font-family: 'Noto Serif';
}

.email {
    font-size: 1.25rem;
    font-family: 'Noto Serif';
}