  .home_body {
    /* background-color: antiquewhite; */
    padding: 2rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .home_section_1 {
    background-color: white;
    padding: 2rem;
  }

  .non_profit_message {
    /* text-align:center; */
    font-size: 3rem;
    width: 70%;
    /* width: 20rem; */
    /* background-color: aqua; */
    padding-left: 2rem;
    font-family: 'Noto Serif';
    /* font-weight:bolder; */
  }

  .home_section_2 {
    background-color: #00567a;
    margin-top: 2rem;
    /* padding: 2rem; */
  }

  .mission_title {
    padding: 1rem;
    text-align: center;
    font-family: 'Noto Serif';
    font-size: 3.5rem;
    padding-top: 1.5rem;
    color: white;
    /* background-color:blue; */
  }

  .mission_content {
    /* text-align:center; */
    display: flex;
    flex-wrap: wrap;
    font-size: 1.5rem;
    color: white;
    width: 60%;
    padding: 1.5rem;
    background-color: #ff8c19;
    /*include later */
    justify-content: center;
    align-content: center;
    font-family: 'Noto Serif';
    /* width: 20rem; */
    /* background-color: aqua; */
    /* padding-left: 2rem; */
  }

  .mission_content_container {
    display: flex;
    flex-direction: row;
    padding: 2rem;
    justify-content: center;
  }

  .medicines_image {
    height: 450px;
    width: 45%;
    padding: 2rem;
    background-color: aqua;
    text-align: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 3rem;
  }

  .home_section_3 {
    background-color: #00567a;
    padding: 2rem;
    margin-top: 2rem;
  }

  .work_title {
    /* padding: 1rem; */
    text-align: center;
    font-family: 'Noto Serif';
    font-size: 3.5rem;
    /* padding-top: 1.5rem; */
    color: white;
    margin-bottom: 4rem;
    /* color: white; */
    /* background-color:blue; */
  }

  .work_content_container {
    display: flex;
    flex-direction: row;
    padding: 2rem;
    justify-content: space-evenly;
    padding-top: 0;
  }

  .work_content {
    /* text-align:center; */
    display: flex;
    flex-wrap: wrap;
    font-size: 1.5rem;
    color: white;
    /* width: 35%; */
    padding: 1.5rem;
    height: fit-content;
    background-color: #ff8c19;
    /*include later */
    justify-content: center;
    align-content: center;
    font-family: 'Noto Serif';
    /* margin-top: 2.5rem; */
    /* width: 20rem; */
    /* background-color: aqua; */
    /* padding-left: 2rem; */
  }

  .home_page_images {
    margin: 2rem;
    display: flex;
    
  }

  .image_container {
    display: flex;
    flex-direction: column;
  }

  .image_title {
    font-weight: bold;
    font-family: 'Noto Serif';
    text-align: center;
    color: white;
    padding-top: 2rem;
    font-size: 1.5rem;
  }